import { View } from "react-native"
import { ActivityIndicator, Button, Card, Dialog, Divider, Drawer, IconButton, MD3Colors, Portal, ProgressBar, Text, TextInput, useTheme } from "react-native-paper"
import { MainWrapper } from "../components"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../RootStackParamList"
import { useCallback, useContext, useEffect, useState } from "react"
import { AppInfo, AppVersion, BuildInfo, BuildStatus, evaluateProgress, evaluateStatus } from "../models"
import { useFocusEffect } from "@react-navigation/native"
import moment from "moment"
import { AuthContext } from '../context';
import { AuthenticatedAxios } from "../auth"
import { signOut } from "firebase/auth"
import { getApiUrl } from "../configuration"
import { NotificationPayload, SendNotification } from "../services"
export enum AppVersionType {
    IOS,
    ANDROID
}
export const AppVersions = ({ navigation, route }: NativeStackScreenProps<RootStackParamList, "AppVersions">) => {
    const { auth } = useContext(AuthContext);
    const { appId } = route.params;
    const [app, setApp] = useState<AppInfo | null>(null);
    const [versions, setVersions] = useState<AppVersion[]>([]);
    const [versionsIOS, setVersionsIOS] = useState<AppVersion[]>([]);

    const [loading, setLoading] = useState(false);
    const [tooltipContent, setTooltipContent] = useState<AppVersion | null>(null);
    const [publicLinkSettings, setPublicLinkSettings] = useState<AppVersion | null>(null);
    const [publicLink, setPublicLink] = useState<string>();
    const [editingChangelog, setEditingChangelog] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const toggleTooltip = (val: AppVersion | null = null) => {
        setEditingChangelog(false);
        setNewChangelog(val?.versionNotes ?? "");
        setTooltipContent(val);
    }

    const [newChangelog, setNewChangelog] = useState("");
    const [newTitle, setNewTitle] = useState("");
    useFocusEffect(useCallback(() => {
        initialize();
    }, [navigation, route]))
    const initialize = async () => {
        await getApp();
        await getAppVersions();
        await getAppVersionsIOS();
    }
    const getApp = async () => {
        try {
            var res = await AuthenticatedAxios.get<AppInfo>(`${getApiUrl()}/AppVersions/GetApp/${appId}`);
            setNewTitle(res.data?.userFacingName ?? "");
            setApp(res.data);

        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const getAppVersions = async () => {
        try {
            var res = await AuthenticatedAxios.get<AppVersion[]>(`${getApiUrl()}/AppVersions/GetAppVersions/${appId}`);
            setVersions(res.data);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const getAppVersionsIOS = async () => {
        try {
            var res = await AuthenticatedAxios.get<AppVersion[]>(`${getApiUrl()}/AppVersions/GetAppVersionsIOS/${appId}`);
            setVersionsIOS(res.data);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const updateVersionNotes = (id: number, val: string) => {
        var copy = [...versions];
        var index = copy.findIndex(x => x.id == id);
        copy[index].versionNotes = val;
        setVersions([...copy]);
    }
    const updatePublicLinkCode = (id: number, val: string | undefined) => {
        var copy = [...versions];
        var index = copy.findIndex(x => x.id == id);
        copy[index].publicLinkCode = val;
        setVersions([...copy]);
    }
    const updateTitle = (val: string) => {
        var appCopy = { ...app! };
        appCopy.userFacingName = val;
        setApp({ ...appCopy });
    }
    const saveTitle = async () => {
        try {
            var res = await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/UpdateAppName/${appId}?appName=${newTitle}`);
            if (res.status == 200)
                updateTitle(newTitle);
            else
                setNewTitle(app?.userFacingName!);
            setEditingTitle(false);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const setPublicLinkInfo = (version: AppVersion | null) => {
        if (version != null) {
            if (version.publicLinkCode)
                setPublicLink(buildPublicLink(version.publicLinkCode));
            setPublicLinkSettings(version);
        } else {
            setPublicLink(undefined);
            setPublicLinkSettings(null);
        }
    }
    const buildPublicLink = (code: string) => { return `${getApiUrl()}/PublicLink/${code}` }
    const saveNotes = async () => {
        try {
            var res = await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/UpdateVersionNotes/${tooltipContent?.id}?notes=${encodeURIComponent(newChangelog)}`);
            if (res.status == 200)
                updateVersionNotes(tooltipContent?.id!, newChangelog);
            setEditingChangelog(false);

        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const createPublicLink = async () => {
        try {
            var res = await AuthenticatedAxios.post<string>(`${getApiUrl()}/PublicLink/CreatePublicLink/${publicLinkSettings?.id}`);
            if (res.status == 200) {
                setPublicLink(buildPublicLink(res.data));
                updatePublicLinkCode(publicLinkSettings?.id!, res.data)
            }
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const copyPublicLink = () => {
        if (publicLink) {
            navigator.clipboard.writeText(publicLink);
            alert("Linket er kopieret til udklipsholderen");
        }
    }
    const deletePublicLink = async () => {
        var confirmed = confirm("Er du sikker på du vil slette dette link? Alle der har linket vil ikke længere kunne bruge det");
        if (confirmed) {
            try {
                var res = await AuthenticatedAxios.delete(`${getApiUrl()}/PublicLink/DeletePublicLink/${publicLinkSettings?.id}`)
                if (res.status == 200) {
                    setPublicLink(undefined);
                    updatePublicLinkCode(publicLinkSettings?.id!, undefined)
                }
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
        }
    }
    const deleteApp = async () => {
        var confirmed = confirm("Er du sikker på du vil slette hele appen med alle versioner?\nDette kan ikke fortrydes");
        if (confirmed) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/DeleteApp/${appId}`)
                navigation.replace("Home");
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
        }
    }
    const deleteVersion = async (id: number) => {
        if (!id)
            return;
        var confirmed = confirm("Er du sikker på du vil slette denne version?\nDette kan ikke fortrydes");
        if (confirmed) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/DeleteAppVersion/${id}`)
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
            await initialize();
        }
    }
    const deleteVersionIOS = async (id: number) => {
        if (!id)
            return;
        var confirmed = confirm("Er du sikker på du vil slette denne version?\nDette kan ikke fortrydes");
        if (confirmed) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/DeleteAppVersionIOS/${id}`)
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
            await initialize();
        }
    }
    const sendDiscordNotification = async (version?: string, versionCode?: string, versionId?: number, versionNotes?: string) => {
        if (version && versionId)
            SendNotification(new NotificationPayload(appId, app?.userFacingName, versionId, version, versionCode, versionNotes))
    }
    return (
        <>
            <MainWrapper>
                <Button style={{ alignItems: "flex-start", width: 120, marginBottom: 10 }} mode="contained" onPress={() => { navigation.replace("Home") }} icon={"arrow-left"}>Tilbage</Button>
                <Card>
                    {
                        editingTitle ?
                            <View style={{ flexDirection: "row" }}>
                                <TextInput value={newTitle} onChangeText={(e) => { setNewTitle(e); }}></TextInput>
                                <IconButton onPress={saveTitle} icon={"check"} />
                            </View>
                            :
                            <Card.Title titleStyle={{ fontSize: 24, alignContent: "center" }} title={(app?.userFacingName ?? "Henter data...")} left={() =>
                                <IconButton style={{ display: (loading ? "none" : "flex") }} onPress={() => { setEditingTitle(true) }} icon={"note-edit-outline"} />
                            } right={() => <Button icon={"trash-can-outline"} textColor="pink" onPress={deleteApp}>Slet app</Button>} />
                    }
                    <Card.Content >
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            <ActivityIndicator style={{ display: (loading ? "flex" : "none") }} animating={loading} />
                            {versions && versions.length > 0 && versions.map((appVersion, index) =>
                                <AppVersionElement key={appVersion.id} version={appVersion} onPublicLinkClick={() => { setPublicLinkInfo(appVersion) }} onTooltipClick={() => { toggleTooltip(appVersion) }} onDeleteClick={() => { deleteVersion(appVersion.id) }} type={AppVersionType.ANDROID} />
                            )}

                        </View>
                    </Card.Content>
                </Card>
                <Card style={{ marginTop: 30 }}>
                    <Card.Title title={"IOS builds"} />
                    <Card.Content >
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            <ActivityIndicator style={{ display: (loading ? "flex" : "none") }} animating={loading} />
                            {versionsIOS && versionsIOS.length > 0 && versionsIOS.map((appVersion, index) =>
                                <AppVersionElement key={appVersion.id} version={appVersion} onPublicLinkClick={() => { }} onTooltipClick={() => { }} onDeleteClick={() => { deleteVersionIOS(appVersion.id) }} type={AppVersionType.IOS} />
                            )}

                        </View>
                    </Card.Content>
                </Card>
            </MainWrapper >
            <Portal>
                <Dialog visible={tooltipContent != null} style={{ maxWidth: 400, alignSelf: "center" }} onDismiss={toggleTooltip}>
                    <Dialog.Title>Changelog</Dialog.Title>
                    <Dialog.Content>
                        {
                            editingChangelog ?
                                <TextInput multiline value={newChangelog} onChangeText={(e) => { setNewChangelog(e); }}></TextInput>
                                :
                                <Text variant="bodyMedium">{tooltipContent?.versionNotes}</Text>
                        }
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={() => { sendDiscordNotification(tooltipContent?.versionName, tooltipContent?.versionCode, tooltipContent?.id, tooltipContent?.versionNotes) }}>Send discord-notifikation</Button>
                        {
                            editingChangelog ?
                                <Button onPress={saveNotes}>Gem ændringer</Button>
                                : <Button onPress={() => { setEditingChangelog(true) }}>Ændr noter</Button>
                        }
                        <Button onPress={() => { toggleTooltip() }}>Luk</Button>
                    </Dialog.Actions>
                </Dialog>
                <Dialog visible={publicLinkSettings != null} style={{ maxWidth: 400, alignSelf: "center" }} onDismiss={() => { setPublicLinkInfo(null) }}>
                    <Dialog.Title>Offentlig link</Dialog.Title>
                    <Dialog.Content>
                        <Text style={{ fontStyle: "italic", marginBottom: 10, fontSize: 13 }}>Offentlige links kan bruges til at dele apk filer med eksterne testere, på en sikker måde.</Text>
                        <Text style={{ fontStyle: "italic", marginBottom: 10, fontSize: 13 }}>Links kan til hver tid slettes, hvorefter brugere ikke længere vil kunne tilgå apk filen</Text>
                        <Divider style={{ height: 5, marginBottom: 10 }} />
                        {
                            publicLinkSettings?.publicLinkCode ?
                                <Text onPress={copyPublicLink} style={{ backgroundColor: "#3b3843", padding: 10 }}>
                                    <Text style={{fontStyle: "italic"}}>Tryk på denne boks for at kopiere linket</Text>
                                    <br />
                                    <br />
                                    {publicLink}
                                </Text>
                                : <Text>Ingen links oprettet for denne version</Text>
                        }
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={createPublicLink}>Opret nyt link</Button>
                        {
                            publicLinkSettings?.publicLinkCode &&
                            <Button onPress={deletePublicLink}>Slet link</Button>
                        }
                        <Button onPress={() => { setPublicLinkInfo(null) }}>Luk</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </>
    )
}
export const AppVersionElement = ({ version, onTooltipClick, onDeleteClick, onPublicLinkClick, type }: { version: AppVersion, onTooltipClick: () => void, onPublicLinkClick: () => void, onDeleteClick: () => void, type: AppVersionType }) => {
    const { auth } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loadingBuild, setLoadingBuild] = useState(false);
    const [building, setBuilding] = useState(false);
    const [buildInfo, setBuildInfo] = useState<BuildInfo>();
    const [latestBuild, setLatestBuild] = useState<BuildInfo>();
    const [openDrawer, setOpenDrawer] = useState(false);
    const height = 100;
    useEffect(() => {
        if (type == AppVersionType.IOS)
            checkForBuild();
    }, [building])
    const checkForBuild = async () => {
        try {
            var build = await AuthenticatedAxios.get<BuildInfo>(`${getApiUrl()}/AppVersions/GetBuildInfoByVersion/${version.id}`);
            if (build.data) {
                setBuilding(true);
                var progress = evaluateProgress(build.data.status as BuildStatus);
                var status = evaluateStatus(build.data.status as BuildStatus);
                setBuildInfo({ ...build.data, progress: progress, statusTextFront: status })
                setTimeout(async () => {
                    await checkForBuild()
                }, 5000);
            } else {
                setBuilding(false);
                setBuildInfo(undefined);
                await getLatestBuild();
            }
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
            else
                console.log(error);
        }
    }
    const getLatestBuild = async () => {
        try {
            var build = await AuthenticatedAxios.get<BuildInfo>(`${getApiUrl()}/AppVersions/GetLatestIOSBuild/${version.id}`);
            if (build.data) {
                var progress = evaluateProgress(build.data.status as BuildStatus);
                var status = evaluateStatus(build.data.status as BuildStatus);
                setLatestBuild({ ...build.data, progress: progress, statusTextFront: status })
            }
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
            else
                console.log(error);
        }
    }

    const buildVersion = async () => {
        try {
            setLoadingBuild(true);
            var build = await AuthenticatedAxios.post<BuildInfo>(`${getApiUrl()}/AppVersions/StartIOSBuild/${version.id}`);
            setBuilding(true);
            setBuildInfo(build.data);
            setLoadingBuild(false);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
            else
                console.log(error);
        }
    }
    const stopBuild = async () => {
        try {
            await AuthenticatedAxios.post(`${getApiUrl()}/AppVersions/StopIOSBuild/${version.id}`);
            await checkForBuild();
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
            else
                console.log(error);
        }
    }
    const getFile = async () => {
        setLoading(true);
        AuthenticatedAxios({
            url: `${getApiUrl()}/AppVersions/${(type == AppVersionType.ANDROID ? "GetAppApk" : "GetIOSBuild")}/${version.id}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${version.versionName}(${version.versionCode}).${(type == AppVersionType.ANDROID ? "apk" : "zip")}`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((err) => {
            if (err && err.response && err.response.status == 401 && auth)
                signOut(auth)
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <View>

            <Card elevation={5} style={{ height: height, marginEnd: 20, marginBottom: 20, flex: 1, flexDirection: "row", alignItems: "center" }}>
                <Card.Content style={{ padding: 5, flex: 1 }}>
                    <View style={{ flexDirection: "row" }}>
                        {
                            loading ?
                                <ActivityIndicator style={{ width: 58, height: 58 }} animating={true} />
                                :
                                <IconButton icon={"download"} loading={loading} size={30} disabled={loading} onPress={getFile} />
                        }
                        <View style={{ display: "flex", alignSelf: "center" }}>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>Version {version.versionName}({version.versionCode})</Text>
                            <Text style={{ fontSize: 12, marginTop: 5, fontWeight: "100" }}>{moment(version.createdAt).format("DD-MM-YYYY HH:mm")}</Text>
                        </View>
                        {
                            type == AppVersionType.ANDROID ?
                                <>
                                    <IconButton style={{ alignSelf: "center", margin: 0 }} onPress={onTooltipClick} icon={"comment-question"} />
                                    <IconButton style={{ alignSelf: "center", margin: 0 }} onPress={onPublicLinkClick} icon={"key-link"} />
                                </>
                                :
                                <>
                                    {
                                        building ?
                                            <IconButton disabled={loadingBuild} loading={loadingBuild} style={{ alignSelf: "center", margin: 0 }} onPress={stopBuild} icon={"stop-circle-outline"} />
                                            :
                                            <IconButton disabled={loadingBuild || building} loading={loadingBuild} style={{ alignSelf: "center", margin: 0 }} onPress={buildVersion} icon={"hammer-wrench"} />
                                    }
                                    <IconButton style={{ alignSelf: "center", margin: 0 }} onPress={() => { setOpenDrawer(!openDrawer) }} icon={"list-status"} />
                                </>
                        }
                        <IconButton style={{ alignSelf: "center", margin: 0 }} onPress={onDeleteClick} icon={"trash-can-outline"} iconColor="pink" />
                    </View>
                    {
                        type == AppVersionType.IOS &&
                            building ?
                            <View>
                                <Text style={{ textAlign: "center", marginBottom: 5 }}>{buildInfo?.statusTextFront}</Text>
                                <ProgressBar progress={buildInfo?.progress} color={MD3Colors.primary50} />
                            </View> : latestBuild &&
                            <View>
                                <Text style={{ textAlign: "center", marginBottom: 5 }}>{latestBuild?.statusTextFront} {latestBuild.status == "FAILED" && <IconButton onPress={() => { alert(latestBuild.errorMessage) }} style={{ width: 25, height: 25, margin: 0, position: "absolute", right: 0, top: -5 }} iconColor="rgb(249 58 58)" icon={"alert-octagon-outline"} size={20} />}</Text>
                                <ProgressBar progress={latestBuild?.progress} color={(latestBuild.status == "FAILED" ? MD3Colors.error50 : "rgb(54 143 78)")} />
                            </View>
                    }
                </Card.Content>
            </Card>

            {
                openDrawer && <InfoDrawer close={() => { setOpenDrawer(false); }} versionId={version.id} />
            }
        </View>
    )
}

const InfoDrawer = (props: { close: () => void, versionId: number }) => {
    const { auth } = useContext(AuthContext);
    const theme = useTheme();
    const [builds, setBuilds] = useState<BuildInfo[]>([])
    useEffect(() => {
        loadBuilds();
    }, [0])
    const loadBuilds = async () => {
        try {
            var build = await AuthenticatedAxios.get<BuildInfo[]>(`${getApiUrl()}/AppVersions/GetAllBuildsForVersion/${props.versionId}`);
            if (build.data)
                setBuilds([...build.data]);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
            else
                console.log(error);
        }
    }
    const downloadLogFile = async (buildId: number) => {
        AuthenticatedAxios({
            url: `${getApiUrl()}/AppVersions/DownloadLogFile/${buildId}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `BuildLog_${buildId}.txt`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((err) => {
            if (err && err.response && err.response.status == 401 && auth)
                signOut(auth)
        })
    }
    return (
        <Portal>
            <View style={{ position: "absolute", left: 0, top: 0, bottom: 0, width: 300, padding: 10, backgroundColor: theme.colors.elevation.level1 }}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Text style={{ fontSize: 20, textAlign: "center" }}>Tidligere Builds</Text>
                    <IconButton style={{ alignSelf: "center", margin: 0 }} onPress={props.close} icon={"close"} iconColor="pink" />
                </View>
                {
                    builds && builds.length > 0 ? builds.map((build, index) =>
                        <Card key={index} elevation={5} contentStyle={{ width: "100%", height: "100%" }} style={{ marginEnd: 20, marginBottom: 20, flexDirection: "row", alignItems: "center" }}>
                            <Card.Content style={{ padding: 10, flex: 1 }}>
                                <View style={{ display: "flex", alignSelf: "center", width: "100%" }}>
                                    <Text style={{ fontSize: 12, textAlign: "center", marginBottom: 5, fontWeight: "bold" }}>Build Id: {build.id}</Text>
                                    <Text style={{ fontSize: 12, justifyContent: "space-between", display: "flex", marginBottom: 5 }}><Text>Startet</Text> <Text>{moment(build.createdAt).format("DD-MM-YYYY HH:mm")}</Text></Text>
                                    <Text style={{ fontSize: 12, justifyContent: "space-between", display: "flex", marginBottom: 5 }}><Text>Begyndt</Text> <Text>{build.pickedUpAt ? moment(build.pickedUpAt).format("DD-MM-YYYY HH:mm") : "--:--"}</Text></Text>
                                    <Text style={{ fontSize: 12, justifyContent: "space-between", display: "flex", marginBottom: 5 }}><Text>Afsluttet</Text> <Text>{build.endedAt ? moment(build.endedAt).format("DD-MM-YYYY HH:mm") : "--:--"}</Text></Text>
                                    <Text style={{ fontSize: 12, justifyContent: "space-between", display: "flex", marginBottom: 5 }}><Text>Status</Text> <Text style={{ color: (build.status == "FAILED" ? "rgb(249 58 58)" : build.status == "COMPLETED" ? "rgb(96 249 58)" : "") }}>{evaluateStatus(build.status as BuildStatus)}</Text></Text>
                                    {
                                        build.status == "FAILED" &&
                                        <>
                                            <Text style={{ fontSize: 12, textAlign: "center", color: "rgb(249 58 58)" }}>Fejl</Text>
                                            <Text style={{ fontSize: 12, textAlign: "center" }}>{build.errorMessage}</Text>
                                        </>
                                    }
                                    <Text style={{ fontSize: 12, justifyContent: "center", display: (build.hasLogFile ? "flex" : "none"), marginBottom: 5, marginTop: 10 }}><Button disabled={!build.hasLogFile} mode="text" onPress={() => { downloadLogFile(build.id) }}>{build.hasLogFile ? "Download logfil" : "Ingen logfil"}</Button></Text>
                                </View>
                            </Card.Content>
                        </Card>
                    )
                        : <Text>Ingen tidligere builds</Text>
                }
            </View>
        </Portal>
    );
};