import { Text, Card, IconButton, Button, ActivityIndicator } from 'react-native-paper';
import { MainWrapper } from '../components';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { useCallback, useContext, useState } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { RootStackParamList } from '../RootStackParamList';
import { AppInfo } from '../models';
import { useFocusEffect } from '@react-navigation/native';
import { AuthContext } from '../context';
import { NotificationPayload, SendNotification } from '../services';
import { AuthenticatedAxios } from '../auth';
import { signOut } from 'firebase/auth';
import { getApiUrl } from '../configuration';
import { IOSUploadModal, UploadModal } from '../components/UploadModal';
import moment from "moment"

export const HomePage = (props: NativeStackScreenProps<RootStackParamList, "Home">) => {
    const { auth } = useContext(AuthContext);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [iOSUploadVisible, setiOSUploadVisible] = useState(false);
    const [apps, setApps] = useState<AppInfo[]>([]);
    const [latestUpdatedApps, setLatestUpdatedApps] = useState<AppInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const showModal = () => setUploadVisible(true);
    const showIOSModal = () => setiOSUploadVisible(true);
    useFocusEffect(useCallback(() => {
        initialize();
    }, [props]))
    const initialize = async () => {
        setLoading(true);
        await loadApps();
        await loadLatestApps();
        setLoading(false);
    }
    const loadApps = async () => {
        try {
            var res = await AuthenticatedAxios.get<AppInfo[]>(`${getApiUrl()}/AppVersions/GetApps`);
            setApps(res.data);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const loadLatestApps = async () => {
        try {
            var res = await AuthenticatedAxios.get<AppInfo[]>(`${getApiUrl()}/AppVersions/GetLatestUpdatedApps`);
            setLatestUpdatedApps(res.data);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const onAppPress = (id: number) => {
        props.navigation.push("AppVersions", { appId: id });
    }
    const onAppUploaded = (app: AppInfo, sendNotification: boolean, versionId?: number, versionCode?: string, versionNotes?: string) => {
        if (sendNotification) {
            SendNotification(new NotificationPayload(app.id, app?.userFacingName, versionId, app.latestVersion, versionCode, versionNotes))
        } else {
            console.log(`Not sending notification`);
        }
        initialize();
    }
    return (
        <MainWrapper>
            <Card style={{ marginBottom: 40 }}>
                <Card.Title titleStyle={{ fontSize: 24 }} title="Senest opdateret" />
                <Card.Content style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <ActivityIndicator animating={loading} style={{ display: (loading ? "flex" : "none") }} />
                    {latestUpdatedApps.map((app, index) =>
                        <AppElement key={index} {...props} app={app} onItemClick={onAppPress} />
                    )}
                </Card.Content>
            </Card>
            <Card>
                <Card.Title titleStyle={{ fontSize: 24 }} title="Alle apps" right={() => <View style={{ flexDirection: "row" }}>
                    <Button onPress={showIOSModal} style={{ marginEnd: 10 }} mode={"contained-tonal"} icon={"upload"}>Upload iOS build</Button>
                    <Button onPress={showModal} style={{ marginEnd: 10 }} mode={"contained-tonal"} icon={"upload"}>Upload apk</Button>
                </View>} />
                <Card.Content style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <ActivityIndicator animating={loading} style={{ display: (loading ? "flex" : "none") }} />
                    {apps.sort((a, b) => a.userFacingName.localeCompare(b.userFacingName)).map((app, index) =>
                        <AppElement key={index} {...props} app={app} onItemClick={onAppPress} />
                    )}
                </Card.Content>
            </Card>
            <UploadModal visible={uploadVisible} setUploadVisible={setUploadVisible} onAppUploaded={onAppUploaded} />
            <IOSUploadModal visible={iOSUploadVisible} setUploadVisible={setiOSUploadVisible} onAppUploaded={() => { initialize() }} />
        </MainWrapper >
    )
}


export const AppElement = (props: { onItemClick: (id: number) => void, app: AppInfo, navigation: NativeStackNavigationProp<any> }) => {
    const width = 100;
    const height = 100;
    return (
        <TouchableOpacity onPress={() => { props.onItemClick(props.app.id) }} style={{ justifyContent: 'center', alignItems: "center", width: width, flexGrow: 1, maxWidth: 120, display: "flex", marginEnd: 20, marginBottom: 20 }}>
            <Card elevation={5} style={{ width: width, height: height, marginBottom: 10 }}>
                <Card.Content style={{ padding: 0, alignSelf: "center" }}>
                    <Image style={{ borderRadius: 10 }} source={{ width: width, height: height, uri: `${getApiUrl()}/AppVersions/GetAppIcon/${props.app.id}` }} />
                    {
                        props.app.updatedAt &&
                        <View style={{ position: "absolute", top: 0, left: 0, right: 0, height: 20, backgroundColor: "#000000b5", borderTopLeftRadius: 10, borderTopRightRadius: 10, flexDirection: "row" }}>
                            <IconButton icon={"arrow-expand-up"} size={10} style={{ margin: 0, padding: 0, width: 20, height: 20 }} iconColor='white' /><Text style={{ fontSize: 12, alignSelf: "center" }}>{moment(props.app.updatedAt).format("DD-MM-YYYY")}</Text>
                        </View>
                    }
                </Card.Content>
            </Card>
            <Text numberOfLines={1} style={{ fontSize: 15 }}>{props.app.userFacingName}</Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
                {
                    props.app.latestVersion &&
                    <Text numberOfLines={1} style={{ fontSize: 12 }}>
                        <IconButton icon={"android"} size={15} style={{ margin: 0, padding: 0, width: 20 }} />{props.app.latestVersion}
                    </Text>
                }
                {
                    props.app.latestVersionIOS &&
                    <Text numberOfLines={1} style={{ fontSize: 12 }}>
                        <IconButton icon={"apple"} size={15} style={{ margin: 0, padding: 0, width: 20 }} />{props.app.latestVersionIOS}
                    </Text>
                }
            </View>
        </TouchableOpacity>
    )
}
