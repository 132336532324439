export class BuildInfo {
    id: number;
    status: string;
    statusTextFront: string;
    progress: number;
    errorMessage: string;
    createdAt: Date;
    pickedUpAt: Date | null;
    endedAt: Date | null;
    hasLogFile: boolean;
}
export enum BuildStatus{
    WAITING = "WAITING",
    DOWNLOADING = "DOWNLOADING",
    UNZIPPING = "UNZIPPING",
    ARCHIVING = "ARCHIVING",
    UPLOADING = "UPLOADING",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED"
}

export const evaluateProgress = (status: BuildStatus) => {
    switch (status) {
        case BuildStatus.WAITING:
            return 0.01;
        case BuildStatus.DOWNLOADING:
            return 0.1;
        case BuildStatus.UNZIPPING:
            return 0.3;
        case BuildStatus.ARCHIVING:
            return 0.5;
        case BuildStatus.UPLOADING:
            return 0.8;
        case BuildStatus.COMPLETED:
            return 1;
        case BuildStatus.FAILED:
            return 1;
        default:
            return 0;
    }
}
export const evaluateStatus = (status: BuildStatus) => {
    switch (status) {
        case BuildStatus.WAITING:
            return "Venter på buildserver..";
        case BuildStatus.DOWNLOADING:
            return "Henter build..";
        case BuildStatus.UNZIPPING:
            return "Åbner zip fil..";
        case BuildStatus.ARCHIVING:
            return "Bygger Xcode projekt";
        case BuildStatus.UPLOADING:
            return "Uploader til store";
        case BuildStatus.COMPLETED:
            return "Upload færdig..";
        case BuildStatus.FAILED:
            return "Build fejlet..";
        default:
            return "Venter på buildserver..";
    }
}